/* eslint-disable jsx-a11y/anchor-is-valid */
import { MoreOutlined } from "@ant-design/icons";
import { DataSourceProps } from "./types";
import useTable from "./useTable";
import colors from "@/utils/colors";

function Table(props: DataSourceProps) {
  const {
    primaryKey,
    filteredKeys,
    values,
    mobileVisibleIndices,
    actionSnackbarKey,
    setActionSnackbarKey,
    actionSnackbarMessage,
    setActionSnackbarMessage,
    primaryKeyIndex,
    optionsIndex,
    hideOptions,
    hidePrimaryKey,
  } = useTable(props);
  const {
    footer,
    hideCheckbox = true,
    checkboxSelected,
    setCheckboxSelected,
    onItemClick,
  } = props;

  const variableElement = ({
    type,
    state,
  }: {
    type: string;
    state: string;
  }) => {
    if (type == "status") {
      return (
        <div
          className={`flex border rounded-full font-heading uppercase text-center items-center justify-center w-18 ${
            state == "Active"
              ? "border-brand-default"
              : "border-tertiary-default"
          }`}
        >
          <p
            className={`leading-4 pt-2 pb-1  text-xxs ${
              state == "Active" ? "text-brand-default" : "text-tertiary-default"
            }`}
          >
            {state}
          </p>
        </div>
      );
    }
    return state;
  };

  return (
    <div className={`overflow-x-auto pb-0 overflow-y-hidden rounded-lg`}>
      <table className="table table-normal w-full ">
        {/* <!-- head --> */}
        <thead className="border-b border-black">
          <tr className="h-16">
            {!hideCheckbox && (
              <th className={`bg-slate-100`}>
                <label>
                  <input
                    onChange={() => {
                      values.length !== checkboxSelected?.length
                        ? setCheckboxSelected!(
                            values.map((x) => x[primaryKeyIndex] as string)
                          )
                        : setCheckboxSelected!([]);
                    }}
                    type="checkbox"
                    className="checkbox"
                    checked={values.length === checkboxSelected?.length}
                  />
                </label>
              </th>
            )}
            {filteredKeys.map((key, index) => (
              <th
                key={index}
                className={`bg-slate-100 text-xs font-light ${
                  hidePrimaryKey && key === primaryKey && "hidden lg:hidden"
                } ${index == optionsIndex && "hidden lg:hidden"} ${
                  !mobileVisibleIndices.some(
                    (mobileIndex: any) => mobileIndex == index
                  ) && "hidden lg:table-cell"
                }`}
              >
                {key}
              </th>
            ))}
            <th className={`bg-slate-100 ${hideOptions && "hidden"}`}></th>
          </tr>
        </thead>
        <tbody className="border-b border-black">
          {values.map((row, rowIndex) => (
            <>
              <tr key={rowIndex} className="h-16">
                {!hideCheckbox && (
                  <th className="bg-slate-200">
                    <label>
                      <input
                        onChange={() => {
                          if (checkboxSelected == undefined) {
                            return setCheckboxSelected!([
                              row[primaryKeyIndex] as string,
                            ]);
                          } else {
                            return checkboxSelected?.includes(
                              row[primaryKeyIndex] as string
                            )
                              ? setCheckboxSelected!(
                                  checkboxSelected.filter(
                                    (x) => x != row[primaryKeyIndex]
                                  )
                                )
                              : setCheckboxSelected!([
                                  ...checkboxSelected!,
                                  row[primaryKeyIndex] as string,
                                ]);
                          }
                        }}
                        type="checkbox"
                        className="checkbox"
                        checked={checkboxSelected?.includes(
                          row[primaryKeyIndex] as string
                        )}
                      />
                    </label>
                  </th>
                )}
                {row.map((data: any, index) =>
                  data?.type === undefined ? (
                    <td
                      onClick={() =>
                        onItemClick != undefined
                          ? onItemClick!(row[primaryKeyIndex] as string)
                          : {}
                      }
                      key={index}
                      className={`bg-slate-200 text-xxs ${
                        hidePrimaryKey &&
                        index == primaryKeyIndex &&
                        "hidden lg:hidden"
                      } ${index == optionsIndex && "hidden lg:hidden"} ${
                        !mobileVisibleIndices.some(
                          (mobileIndex: any) => mobileIndex == index
                        ) && " hidden lg:table-cell"
                      }`}
                    >
                      <div
                        className={`${data}`.length > 18 ? "tooltip" : ""}
                        data-tip={`${data}`.length > 18 ? `${data}` : ""}
                      >
                        <div>
                          {`${data}` === "null"
                            ? ""
                            : `${data}`.length > 18
                            ? `${data}`.slice(0, 18) + "..."
                            : `${data}`}
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td
                      onClick={() =>
                        onItemClick != undefined
                          ? onItemClick!(row[primaryKeyIndex] as string)
                          : {}
                      }
                      key={index}
                      className={` ${
                        hidePrimaryKey &&
                        index == primaryKeyIndex &&
                        " hidden lg:hidden"
                      } ${
                        !mobileVisibleIndices.some(
                          (mobileIndex: any) => mobileIndex == index
                        ) && "hidden lg:table-cell"
                      }`}
                    >
                      {variableElement(data)}
                    </td>
                  )
                )}
                <td className={`bg-slate-200 ${hideOptions && "hidden"}`}>
                  <div
                    className={`flex w-full h-full items-center justify-center `}
                  >
                    <div className="dropdown dropdown-left">
                      <label tabIndex={0}>
                        <MoreOutlined
                          style={{ color: colors["slate-700"], fontSize: 25 }}
                        />
                      </label>
                      <ul
                        tabIndex={0}
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        {
                          // @ts-ignore
                          (optionsIndex === -1 ? [] : row[optionsIndex]).map(
                            (option: any, index: any) => (
                              <li
                                key={index}
                                onClick={() => {
                                  option.action(row[primaryKeyIndex]);
                                  option.actionMessage != "" &&
                                    setActionSnackbarKey(
                                      row[primaryKeyIndex] as number
                                    );
                                  option.actionMessage != "" &&
                                    setActionSnackbarMessage(
                                      option.actionMessage
                                    );
                                }}
                              >
                                <a>{option.name}</a>
                              </li>
                            )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              {
                // @ts-ignore
                row[primaryKeyIndex] === actionSnackbarKey && (
                  <tr
                    key={
                      primaryKeyIndex != -1
                        ? `${row[primaryKeyIndex]}-snackbar`
                        : `${rowIndex}-snackbar`
                    }
                  >
                    <td
                      colSpan={100}
                      className=" bg-backgroundAccentTertiary-default rounded-xl"
                    >
                      <div className="flex flex-row items-center justify-center">
                        {/* <div className='h-7 pr-4'>{!(props.isLoading) ? <TickIcon /> : <LoadingIcon size='small' />}</div> */}
                        <p className="text-center text-xxs font-bold text-black">
                          {!props.isLoading && actionSnackbarMessage}
                        </p>
                      </div>
                    </td>
                  </tr>
                )
              }
            </>
          ))}
        </tbody>
        <tfoot className=" rounded-b-xl">
          <tr className="rounded-b-xl h-16">
            {!hideCheckbox && (
              <th className="bg-slate-100">
                <label>
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={() => {
                      values.length !== checkboxSelected?.length
                        ? setCheckboxSelected!(
                            values.map((x) => x[primaryKeyIndex] as string)
                          )
                        : setCheckboxSelected!([]);
                    }}
                    checked={values.length === checkboxSelected?.length}
                  />
                </label>
              </th>
            )}
            {filteredKeys.map((key, index) => (
              <th
                key={index}
                className={`bg-slate-100 text-xs font-light ${
                  hidePrimaryKey && key === primaryKey && "hidden lg:hidden"
                } ${index == optionsIndex && "hidden lg:hidden"} ${
                  !mobileVisibleIndices.some(
                    (mobileIndex: any) => mobileIndex == index
                  ) && "hidden lg:table-cell"
                }`}
              >
                {key}
              </th>
            ))}
            <th className={`bg-slate-100 ${hideOptions && "hidden"}`}></th>
          </tr>
        </tfoot>
      </table>
      <div
        className={`flex items-center justify-center ${footer ? "h-24" : ""}`}
      >
        {footer}
      </div>
    </div>
  );
}

export default Table;
